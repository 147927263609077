<template>
	<div>
		<BaseLoading v-if="isLoading" is-full-page />
		<div
			v-else
			class="main-wrapper-large"
		>
			<!-- General information -->
			<CRow>
				<CCol>
					<h2 class="typo-h4 mt-4 d-flex align-items-center">
						Item info
					</h2>
				</CCol>
			</CRow>
			<CRow class="summary">
				<CCol md="12">
					<p class="order-meta mb-2 p-0">
						Username: <span class="order-meta-value">{{ info.userName }}</span>
					</p>
					<p class="order-meta mb-2 p-0">
						Name: <span class="order-meta-value">{{ info.fullName }}</span>
					</p>
					<p class="order-meta mb-2 p-0">
						Date / Time: <span class="order-meta-value">{{ info.date }}</span>
					</p>
					<p class="order-meta mb-2 p-0">
						IP address: <span class="order-meta-value">{{ info.ipAddress }}</span>
					</p>
					<p class="order-meta mb-2 p-0">
						User agent: <span class="order-meta-value">{{ info.userAgent }}</span>
					</p>
					<p class="order-meta mb-2 p-0">
						Event: <span class="order-meta-value">{{ info.event }}</span>
					</p>
					<p class="order-meta mb-2 p-0">
						ID / Entity: <span class="order-meta-value">{{ info.entities }}</span>
					</p>
				</CCol>
			</CRow>
			<!-- Table -->
			<CRow>
				<CCol md="12">
					<div class="audit-table mt-4">
						<div class="audit-heading">
							Old value
						</div>
						<div class="audit-content">
							<pre>{{ oldValues }}</pre>
						</div>
					</div>
					<div class="audit-table mt-4">
						<div class="audit-heading">
							New value
						</div>
						<div class="audit-content">
							<pre>{{ newValues }}</pre>
						</div>
					</div>
				</CCol>
			</CRow>
		</div>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
	name: 'AuditLogDetail',

	data() {
		return {
			isLoading: false,
			id: Number(this.$route.params.id) || null,
		};
	},

	computed: {
		...mapState('auditLogs', {
			detail: 'detail',
		}),

		...mapGetters({
			info: 'auditLogs/getAuditLogGeneralInfo',
		}),
		oldValues() {
			return !isEmpty(this.info.oldValues) ? this.info.oldValues : '';
		},
		newValues() {
			return !isEmpty(this.info.newValues) ? this.info.newValues : '';
		},
	},

	async created() {
		await this.getAuditLog(this.id);
	},

	methods: {
		...mapActions({
			getAuditLog: 'auditLogs/getAuditLog',
		}),
	},
};
</script>

<style lang="scss" scoped>
	.order-meta {
		@include typo-body-2;

		// order-meta-value
		&-value {
			color: $color-black-45;
		}
	}

	.audit {
		// .audit-table
		&-table {
			border: rem(1) solid $color-gray-300;
			border-radius: rem(4);
		}
		// .audit-heading
		&-heading {
			background-color: $color-gray-100;
			border-top-right-radius: rem(4);
			border-top-left-radius: rem(4);
			border-bottom: rem(1) solid $color-gray-300;
			color: $color-black-90;
			font-weight: 600;
			padding: rem(12) rem(16);
		}
		// .audit-content
		&-content {
			padding: rem(24) rem(16);
		}
	}
</style>
